import React from "react"

const Ad = () => {
  return (
    <>
      <script language="javascript" src="//ad.jp.ap.valuecommerce.com/servlet/jsbanner?sid=3639419&pid=887916052"></script>
      <noscript>
        <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3639419&pid=887916052" rel="nofollow">
          <img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=3639419&pid=887916052" border="0" />
        </a>
      </noscript>
    </>
  )
}

export default Ad
